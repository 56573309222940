<template>
  <div class="class_home_page">
    <div
      class="config"
      v-show="!$store.state.userInfo.teacherId"
      @click="clickCheck"
    >
      非公益配课（{{ peike }}）
    </div>
    <div class="table_page">
      <div class="table_top">
        <div class="title">{{ $route.query.name }}</div>
        <div class="top_block1 top_block2">
          <div class="title" v-show="!$store.state.userInfo.teacherId">
            (公益配
            课：对平台赠送的学生名额进行分配。非公益配课：对园所自行购买的学生名额进行分配。)
          </div>
          <el-button
            v-show="$store.state.userInfo.teacherId"
            @click="clickGraduation"
            class="table_top_btn_default"
            v-if="$route.query.type == 0"
            >批量毕业</el-button
          >
          <el-button
            v-show="$store.state.userInfo.teacherId"
            @click="handleStuUpload"
            class="table_top_btn_default"
            >批量导入新增学生</el-button
          >
          <el-button
            v-show="$store.state.userInfo.teacherId"
            @click="handleStuComplete"
            class="table_top_btn_default"
            >批量完善学生信息</el-button
          >
          <el-button @click="addStu" class="table_top_btn_default"
            >添加学生 +</el-button
          >
        </div>
      </div>
      <el-table
        header-align="center"
        stripe
        header-cell-class-name="header_row_style"
        :data="list"
        style="width: 100%"
        ref="selectTable"
        @selection-change="handleSelectionChange"
      >
        >
        <el-table-column
          v-if="isCheck || isGraduation"
          type="selection"
          width="55"
          :selectable="selectEnable"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="false"
          :align="item.align || 'center'"
          v-for="item in columns"
          :key="item.prop"
          :prop="item.prop"
          :width="item.width"
          :label="item.label"
        >
        </el-table-column>
        <el-table-column width="150" align="center" label="操作">
          <template slot-scope="scope">
            <div class="table_btns">
              <div class="table_btn" @click="handleEdit(scope.row, 'view')">
                查看
              </div>
              <div class="table_btn" @click="handleEdit(scope.row, 'edit')">
                编辑
              </div>
              <div class="table_btn" @click="handleDel(scope.row)">操作</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-show="isCheck" class="btns">
      <div class="config_submit" @click="handleConfigSubmit">确定</div>
      <div class="config_cancal" @click="handleConfigCancle">取消</div>
    </div>
    <div v-show="isGraduation" class="btns">
      <div class="config_submit" @click="goGraduation">确定</div>
      <div class="config_cancal" @click="cancelGraduation">取消</div>
    </div>
    <comdialog
      :list="fromList"
      ref="comdialog"
      :params.sync="stuInfo"
      :edit="alertStatus"
      :title="
        alertStatus === 'edit'
          ? '编辑学生'
          : alertStatus === 'add'
          ? '新增学生'
          : '查看学生'
      "
      @onSubmit="onSubmit"
      @close="comdialogClose"
      @changeCardType = "changeStuCardType"
    ></comdialog>
    <el-dialog
      title="操作"
      :visible.sync="moreHandleInfo"
      width="30%"
      @before-close="handleMoreInfoClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span>请选择转学或者毕业</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleMoreInfo(1)">转学</el-button>
        <el-button type="primary" @click="handleMoreInfo(2)">毕业</el-button>
        <!-- todo 删除操作缺失-->
        <el-button type="primary" @click="deleteStudent">删除</el-button>
      </span>
    </el-dialog>
    <el-dialog
      width="20%"
      title="批量导入新增学生"
      class="handleStuAlertShow"
      :visible.sync="handleStuAlertShow1"
    >
      <a
        href="https://www.prefootball.cn/assets/football/excel/%E5%AD%A6%E5%91%98%E4%BF%A1%E6%81%AF%E8%A1%A8.xls"
      >
        <div class="btn tab">下载</div>
      </a>
      <p class="title">下载标准格式的excel模板</p>
      <el-upload
        class="upload-demo"
        name="tableFile"
        :disabled="uploadStatus"
        :headers="{ token: $store.state.userInfo.token }"
        :action="constant.UPLOAD_URL + '/atteGrade/excelFileAdd?type=1'"
        :on-success="uploadSuccess"
        :data="{ grade: $route.query.id }"
        :show-file-list="false"
        :before-upload="beforeFileUpload"
      >
        <div class="btn tab" slot="trigger">
          {{ uploadStatus ? "正在上传，请稍后" : "上传" }}
        </div>
      </el-upload>
      <p class="title">上传已经填写完毕的excel表单</p>
      <p style="color: red; font-size: 0.16rem;margin-left:30px;">
        *外籍或持护照等其它证件幼儿请通过“添加学生”录入
      </p>
    </el-dialog>
    <el-dialog
      width="20%"
      title="批量完善学生信息"
      class="handleStuAlertShow"
      :visible.sync="handleStuAlertShow2"
    >
      <a :href="downloadUrl">
        <div class="btn tab">下载</div>
      </a>
      <!-- <div class="btn tab" @click="download">
        {{ downloadStatus ? "正在下载，请稍后" : "下载" }}
      </div> -->
      <p class="title">下载当前的学生名单</p>
      <el-upload
        class="upload-demo"
        name="tableFile"
        :disabled="uploadStatus"
        :headers="{ token: $store.state.userInfo.token }"
        :action="constant.UPLOAD_URL + '/atteGrade/excelFileAdd?type=2'"
        :on-success="uploadSuccess"
        :data="{ grade: $route.query.id }"
        :show-file-list="false"
        :before-upload="beforeFileUpload"
      >
        <div class="btn tab" slot="trigger">
          {{ uploadStatus ? "正在上传，请稍后" : "上传" }}
        </div>
      </el-upload>
      <p class="title">上传当前的学生名单</p>
      <p style="color: red; font-size: 0.16rem">
        *外籍或持护照等其它证件幼儿请通过“添加学生”录入
      </p>
    </el-dialog>
    <el-dialog width="75%" class="batch_result" :visible.sync="handleuploadStatus">
      <span class="dialog_title">{{uploadType == 1 ? '批量导入结果' :'批量完善结果'}}</span>
      <div class="custTab">
        <table class="layui-table" lay-skin="nob">
          <thead>
            <tr class="dialog_table_th">
              <th class="dialog_table_th_icon" ></th>
              <th class="dialog_table_th_num">序号<span class="dialog_table_th_sl"></span></th>
              <th>姓名<span class="dialog_table_th_sl"></span></th>
              <th class="dialog_table_th_sex">性别<span class="dialog_table_th_sl"></span></th>
              <th class="dialog_table_th_birth">生日<span class="dialog_table_th_sl"></span></th>
              <th class="dialog_table_th_cardId">身份证号<span class="dialog_table_th_sl"></span></th>
              <th>家长姓名<span class="dialog_table_th_sl"></span></th>
              <th class="dialog_table_th_phone">家长电话</th>
            </tr>
          </thead>
          <tbody class="dialog_table_tbody" style="height:5.5rem;">
            <tbody v-for="(item, index) in uploadList" :key="item.id" style="position:relative;overflow:hidden;">
              <tr  class="dialog_table_tr">
                <td class="dialog_table_th_icon">
                  <img src="../../../assets/img/school/batch_del.png" width="16" height="16" @click="delStuData(item,index)" />
                </td>
                <td class="dialog_table_th_num">
                  <div class="inpsty" >{{ index + 1 }}</div>
                </td>
                <td>
                  <el-input
                    class="inpsty"
                    v-model.trim="item.name.result"
                    placeholder="请输入姓名"
                    resize="none"
                    @blur="handleUploadInput()"
                    @change="checkName(item)"
                  />
                </td>
                <td class="dialog_table_th_sex">
                  <el-input
                    class="inpsty"
                    v-model.trim="item.sex.result"
                    placeholder="请输入正确的性别，必填"
                    resize="none"
                    :rows="1"
                    @blur="handleUploadInput()"
                    @change="checkSex(item)"
                  />
                </td>
                <td class="dialog_table_th_birth">
                  <el-input
                    class="inpsty"
                    v-model.trim="item.brithDay.result"
                    placeholder="请输入正确的生日，必填"
                    resize="none"
                    @blur="handleUploadInput()"
                    @change="checkBirthday(item)"
                  />
                </td>
                <td class="dialog_table_th_cardId">
                  <el-input
                    class="inpsty"
                    v-model.trim="item.idCard.result"
                    placeholder="请输入正确的身份证号"
                    resize="none"
                    @blur="handleUploadInput()"
                    @change="checkIdCard(item)"
                  />
                </td>
                <td>
                  <el-input
                    class="inpsty"
                    v-model.trim="item.parentName.result"
                    placeholder="请补充家长姓名"
                    resize="none"
                    @blur="handleUploadInput()"
                    @change="checkParentName(item)"
                  />
                </td>
                <td class="dialog_table_th_phone">
                  <el-input
                    class="inpsty"
                    v-model.trim="item.phone.result"
                    placeholder="请补充家长手机号"
                    resize="none"
                    @blur="handleUploadInput()"
                    @change="checkPhone(item)"
                  />
                  <input
                    v-if="index === (uploadList.length - 1)"
                    style="display:block;width:0px;height:0px;opacity:0;"
                    placeholder=""
                    resize="none"
                  />
                </td>
              </tr>
              <tr style="height:.3125rem;">
                <div v-if="item.isDuplicate && uploadType == 1" class="err_tip">
                  <div>
                    <img src="../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>该学员已经存在，请勿重复录入</span>
                  </div>
                </div>
                <div v-else-if="!item.isDuplicate && uploadType == 2" class="err_tip">
                  <div>
                    <img src="../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>此学员非本班学员，新学员请新增录入</span>
                  </div>
                </div>
                <div v-else >
                  <div v-if="!item.name.result || !item.name.flag" class="err_tip name">
                    <img src="../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确姓名</span>
                  </div>
                  <div v-if="!item.sex.result || !item.sex.flag" class="err_tip sex">
                    <img src="../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确性别</span>
                  </div>
                  <div v-if="!item.brithDay.result || !item.brithDay.flag" class="err_tip brithDay">
                    <img src="../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确生日</span>
                  </div>
                  <div v-if="(uploadType == 2 && !item.idCard.result) || !item.idCard.flag" class="err_tip idCard">
                    <img src="../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确身份证号</span>
                  </div>
                  <div v-if="(uploadType == 2 && !item.parentName.result) || !item.parentName.flag" class="err_tip parentName">
                    <img src="../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确家长姓名</span>
                  </div>
                  <div v-if="(uploadType == 2 && !item.phone.result) || !item.phone.flag" class="err_tip phone">
                    <img src="../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确家长电话</span>
                  </div>
                </div>
              </tr>
            </tbody>
          </tbody>
        </table>
      </div>
      <div style="padding:0.95rem 2.0rem; margin-top: -10px; display: flex;background:#FFFFFF;justify-content: space-between;
    align-items: flex-end;">
        <div class="btn_container" >
          <el-button
            class="btn_sty"
            @click="confirmEnd"
            :disabled="!uploadDialogOperate"
            >确认提交</el-button
          >
        </div>
        <div
          class="btn_container"
        >
          <el-button
            class="btn_sty"
            @click="postNow"
            :disabled="!uploadDialogOperate"
            >已经补充并提交</el-button
          >
        </div>
        <div class="btn_container">
          <el-button
            class="btn_sty"
            @click="postAgain"
            >放弃本次结果重新导入</el-button
          >
        </div>
      </div>
    </el-dialog>
    <comPagination :params="params" @changePage="changePage"></comPagination>
  </div>
</template>

<script>
import comdialog from "@/components/admin/editDialog.vue";
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "headerSchool",
  components: {
    comdialog,
    comPagination,
  },
  data() {
    var checkName = (rule, value, callback) => {
      if (!this.common.textNumberCheck(value.trim(), 25)) {
        callback(new Error("请输入正确格式姓名"));
      } else {
        callback();
      }
    };
    var checkIdCard = (rule, value, callback) => {
      if (this.stuCardType == 0) {
        if (value.trim()) {
          if (!this.common.validateIdCard(value)) {
            callback(new Error("请输入正确格式身份证号"));
          } else {
            callback();
          }
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkParentName = (rule, value, callback) => {
      if (value.trim()) {
        if (!this.common.textNumberCheck(value, 25)) {
          callback(new Error("请输入正确格式家长姓名"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkPhone = (rule, value, callback) => {
      if (!value.trim()) {
        callback();
      } else {
        if (!this.common.isMobile(+value)) {
          callback(new Error("请输入正确格式家长联系电话"));
        } else {
          callback();
        }
      }
    };
    return {
      //班级列表
      columns: [
        {
          prop: "serial",
          label: "编号",
        },
        {
          prop: "name",
          label: "姓名",
        },
        {
          prop: "sexName",
          label: "性别",
        },
        {
          prop: "birthday",
          label: "出生日期",
        },
        {
          prop: "integral",
          label: "积分",
        },
        {
          prop: "idCard",
          label: "身份证/护照",
        },
        {
          prop: "parentName",
          label: "家长姓名",
        },
        {
          prop: "phone",
          label: "家长手机号",
        },
        {
          prop: "statusName",
          label: "配课状态",
        },
      ],
      // 非公益配课
      peike: 0,
      list: [],
      //分页
      params: {
        page: 0,
        size: 10,
        total: 0,
      },
      // 选中数据
      isCheck: false,
      multipleSelection: [],

      // 学生信息弹窗
      stuInfoShow: true,
      handleStuAlertShow1: false, // 批量添加学生
      handleStuAlertShow2: false, // 批量完善学生
      handleuploadStatus: false, // 上传成功后显示
      stuEdit: true,
      classOptions: [],
      stuCardType: 0,
      fromList: [
        {
          prop: "name",
          type: "text",
          label: "学生姓名",
          rules: [
            { required: true, message: "请输入姓名", trigger: "blur" },
            { validator: checkName, trigger: "blur" },
          ],
        },
        {
          prop: "idCardType",
          type: "radio",
          label: "证件类型",
          required: false,
          options: [
            {
              label: "身份证",
              value: 0,
            },
            {
              label: "护照",
              value: 1,
            },
            {
              label: "其他证件",
              value: 2,
            },
          ],
        },
        {
          prop: "idCard",
          type: "text",
          label: "证件号",
          required: false,
          rules: [{ validator: checkIdCard, trigger: "blur" }],
        },
        {
          prop: "parentName",
          type: "text",
          label: "家长姓名",
          required: false,
          rules: [{ validator: checkParentName, trigger: "blur" }],
        },
        {
          prop: "photo",
          type: "upload",
          label: "学员照片",
          required: false,
        },
        {
          prop: "birthday",
          type: "date",
          label: "学员出生日期",
          rules: [
            { required: true, message: "请选择学员出生日期", trigger: "blur" },
          ],
        },
        {
          prop: "gradeId",
          type: "select",
          label: "学员所属班级",
          options: [],
          rules: [
            { required: true, message: "请选择学员所属班级", trigger: "blur" },
          ],
        },
        {
          prop: "sex",
          type: "radio",
          label: "学员性别",
          required: true,
          options: [
            {
              label: "男",
              value: 1,
            },
            {
              label: "女",
              value: 2,
            },
          ],
        },
        {
          prop: "phone",
          type: "number",
          label: "家长联系电话",
          rules: [{ validator: checkPhone, trigger: "blur" }],
        },
      ],
      stuInfo: {
        name: "",
        idCardType: 0,
        idCard: "",
        parentName: "",
        photo: "",
        birthday: "",
        gradeId: "",
        sex: 1,
        phone: "",
      },
      isGraduation: false,
      // 弹窗状态  新增/编辑/查看
      alertStatus: "",
      // 更多操作显示
      moreHandleInfo: false,
      // 更多操作已选数据
      tabMoreInfo: {},
      uploadStatus: false,
      downloadUrl: "",
      uploadDialogOperate: true,
      isAllCorrect: false,
      uploadList: [],
      uploadType: 0,
    };
  },
  created() {},
  mounted() {
    this.downloadUrl =
      this.constant.UPLOAD_URL +
      "/excel/download?gradeId=" +
      this.$route.query.id;
    this.init();
  },
  methods: {
    //初始化
    init() {
      this.getfind();
      this.getStuInfo();
      this.getClassInfo();
    },
    beforeFileUpload(file) {
      console.log(file);
      if (this.uploadStatus) {
        this.$message.warning("文件正在上传中，请耐心等待");
        return false;
      } else {
        this.uploadStatus = true;
        return true;
      }
    },
    clickCheck() {
      if (this.peike == 0) {
        this.$message.warning("请先购买课程");
        return;
      }
      if (!this.isCheck) {
        this.isGraduation = false;
        this.isCheck = true;
        this.multipleSelection = [];
        this.$nextTick(() => {
          this.list.forEach((item) => {
            this.$refs.selectTable.toggleRowSelection(item, false);
          });
        });
      }
    },
    // 批量毕业
    clickGraduation() {
      if (!this.isGraduation) {
        this.isGraduation = true;
        this.isCheck = false;
        this.multipleSelection = [];
        this.$nextTick(() => {
          this.list.forEach((item) => {
            this.$refs.selectTable.toggleRowSelection(item, false);
          });
        });
      }
    },
    // 毕业操作
    goGraduation() {
      if (this.multipleSelection.length != 0) {
        let str = this.multipleSelection.map((res) => {
          return res.id;
        });
        this.graduationStudent(str.join(","));
        this.cancelGraduation();
      } else {
        this.$confirm("请选择要毕业的学员", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      }
    },
    cancelGraduation() {
      this.isGraduation = false;
      this.multipleSelection = [];
    },
    // 获取学生信息
    getStuInfo() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        gradeId: this.$route.query.id,
        page: this.params.page,
        size: this.params.size,
      };
      this.api.admin.findStuInfoByGradeId(data).then((res) => {
        let arr = [];
        this.params.total = res.data.total;
        res.data.list &&
          res.data.list.forEach((item, index) => {
            let date = new Date(item.atteStudent.birthday);
            item.atteStudent.birthday = `${date.getFullYear()}-${
              date.getMonth() + 1
            }-${date.getDate()}`;
            arr.push({
              ...item.atteStudent,
              serial:
                "S" +
                this.common.PrefixZero(index + 1 + this.params.page * 10, 4),
              sexName: item.atteStudent.sex === "1" ? "男" : "女",
              status: item.status,
              statusName: item.status === 0 ? "未配课" : "已配课",
            });
          });
        // console.log(arr);
        this.list = arr;
      });
    },
    handleUploadInput() {
      let isAllTrue = true;
      this.uploadList.forEach((item) => {
        if (this.uploadType == 1) {
          if (
            !item.name.result ||
            !item.sex.result ||
            !item.brithDay.result ||
            !item.name.flag ||
            !item.sex.flag ||
            !item.brithDay.flag ||
            !item.idCard.flag ||
            !item.parentName.flag ||
            !item.phone.flag ||
            item.isDuplicate
          ) {
            isAllTrue = false;
            this.uploadDialogOperate = false;
            return;
          }
        } else {
          if (
            !item.name.result ||
            !item.sex.result ||
            !item.brithDay.result ||
            !item.idCard.result ||
            !item.parentName.result ||
            !item.phone.result ||
            !item.name.flag ||
            !item.sex.flag ||
            !item.brithDay.flag ||
            !item.idCard.flag ||
            !item.parentName.flag ||
            !item.phone.flag ||
            !item.isDuplicate
          ) {
            isAllTrue = false;
            this.uploadDialogOperate = false;
            return;
          }
        }
      });
      if (this.uploadList.length == 0) {
        isAllTrue = false;
      }
      this.uploadDialogOperate = isAllTrue;
    },
    //校验名称
    checkName(data) {
      //名称
      if (data.name.result) {
        data.name.result = data.name.result.trim();
        if (!this.common.textNumberCheck(data.name.result.trim(), 25)) {
          this.$set(data.name, "flag", false);
        } else {
          this.$set(data.name, "flag", true);
        }
      } else {
        this.$set(data.name, "flag", true);
      }
    },
    //校验性别
    checkSex(data) {
      //性别
      if (data.sex.result) {
        data.sex.result = data.sex.result.trim();
        if (!this.common.validateSex(data.sex.result.trim())) {
          this.$set(data.sex, "flag", false);
        } else {
          this.$set(data.sex, "flag", true);
        }
      } else {
        this.$set(data.sex, "flag", true);
      }
    },
    //校验生日
    checkBirthday(data) {
      //生日
      if (data.brithDay.result) {
        data.brithDay.result = data.brithDay.result.trim();
        if (!this.common.validateBirthday(data.brithDay.result.trim())) {
          this.$set(data.brithDay, "flag", false);
        } else {
          this.$set(data.brithDay, "flag", true);
        }
      } else {
        this.$set(data.brithDay, "flag", true);
      }
    },
    //校验身份证号
    checkIdCard(data) {
      let list = this.uploadList;
      //身份证
      if (data.idCard.result) {
        data.idCard.result = data.idCard.result.trim();
        if (!this.common.validateIdCard(data.idCard.result.trim())) {
          this.$set(data.idCard, "flag", false);
        } else {
          list.reduce((prev, next) => {
            if (next.idCard.result) {
              prev[next.idCard.result] = prev[next.idCard.result] + 1 || 1;
              if (prev[next.idCard.result] > 1) {
                this.$set(next.idCard, "flag", false);
              } else {
                this.$set(next.idCard, "flag", true);
              }
            }
            return prev;
          }, {});
        }
      } else {
        this.$set(data.idCard, "flag", true);
      }
    },
    //校验家长名称
    checkParentName(data) {
      data.parentName.result = data.parentName.result.trim();
      if (data.parentName.result) {
        if (!this.common.textNumberCheck(data.parentName.result.trim(), 25)) {
          this.$set(data.parentName, "flag", false);
        } else {
          this.$set(data.parentName, "flag", true);
        }
      } else {
        this.$set(data.parentName, "flag", true);
      }
    },
    //校验家长手机号
    checkPhone(data) {
      //手机号
      if (data.phone.result) {
        data.phone.result = data.phone.result.trim();
        if (!this.common.isMobile(data.phone.result.trim())) {
          this.$set(data.phone, "flag", false);
        } else {
          this.$set(data.phone, "flag", true);
        }
      } else {
        this.$set(data.phone, "flag", true);
      }
    },
    confirmEnd() {
      this.handleuploadStatus = false;
      this.uploadList.forEach((item) => {
        item.idCard.result = item.idCard.result.trim();
        item.name.result = item.name.result.trim();
        item.parentName.result = item.parentName.result.trim();
        item.phone.result = item.phone.result.trim();
      });
      console.log(this.uploadList, "this.uploadListthis.uploadList");
      let data = {
        isCorrect: this.uploadDialogOperate,
        isAllCorrect: this.isAllCorrect,
        dtos: this.uploadList,
      };
      this.api.admin
        .addOrupdateBatchStudentS(this.uploadType, data)
        .then((res) => {
          if (res.flag) {
            this.handleStuAlertShow1 = false;
            this.handleStuAlertShow2 = false;
            this.isAllCorrect = res.data.isAllCorrect;
            if (res.data.isAllCorrect) {
              this.$notice({
                type: "success",
                message: res.message,
              }).isShow();
              this.handleuploadStatus = false;
              this.getStuInfo();
            } else {
              if (!res.data.isCorrect) {
                this.$message({
                  message: "内容有误，请检查!",
                  type: "warning",
                  customClass: "zZindex",
                });
              }
              this.handleuploadStatus = true;
              this.uploadDialogOperate = res.data.isCorrect;
              this.uploadList = res.data.dtos;

              //判断数据的准确性
              this.uploadList.forEach((item) => {
                this.checkName(item);
                this.checkSex(item);
                this.checkBirthday(item);
                this.checkIdCard(item);
                this.checkParentName(item);
                this.checkPhone(item);
              });
              this.handleUploadInput();
            }
          } else {
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          }
        });
    },
    postNow() {
      this.handleuploadStatus = false;
      this.uploadList.forEach((item) => {
        item.idCard.result = item.idCard.result.trim();
        item.name.result = item.name.result.trim();
        item.parentName.result = item.parentName.result.trim();
        item.phone.result = item.phone.result.trim();
      });
      console.log(this.uploadList, "this.uploadList");
      let data = {
        isCorrect: this.uploadDialogOperate,
        isAllCorrect: this.isAllCorrect,
        dtos: this.uploadList,
      };
      this.api.admin
        .addOrupdateBatchStudentS(this.uploadType, data)
        .then((res) => {
          if (res.flag) {
            this.handleStuAlertShow1 = false;
            this.handleStuAlertShow2 = false;
            this.isAllCorrect = res.data.isAllCorrect;
            console.log(res.data.isAllCorrect, "res.data.isAllCorrect");
            if (res.data.isAllCorrect) {
              this.$notice({
                type: "success",
                message: res.message,
              }).isShow();
              this.handleuploadStatus = false;
              this.getStuInfo();
            } else {
              if (!res.data.isCorrect) {
                this.$message({
                  message: "内容有误，请检查!",
                  type: "warning",
                  customClass: "zZindex",
                });
              }
              this.handleuploadStatus = true;
              this.uploadDialogOperate = res.data.isCorrect;
              this.uploadList = res.data.dtos;
              //判断数据的准确性
              this.uploadList.forEach((item) => {
                this.checkName(item);
                this.checkSex(item);
                this.checkBirthday(item);
                this.checkIdCard(item);
                this.checkParentName(item);
                this.checkPhone(item);
              });
              this.handleUploadInput();
            }
          } else {
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          }
        });
    },
    postAgain() {
      this.handleuploadStatus = false;
    },

    // 非公益配课
    getfind() {
      let data = {
        schoolId: JSON.parse(localStorage.getItem("userInfo")).schoolId,
      };
      this.api.admin.findHasClass(data).then((res) => {
        this.peike = res.data;
      });
    },
    // 获取学校信息
    getClassInfo() {
      this.api.admin.findGradesDetails().then((res) => {
        let arr = res.data.map((item) => {
          return { label: item.atteGrade.name, value: item.atteGrade.id };
        });
        this.fromList.forEach((item) => {
          if (item.prop === "gradeId") {
            item.options = arr;
          }
        });
      });
    },
    // 学生配课
    handleUpdatePlan(str) {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        stuIds: str,
      };
      this.api.admin.updatePlan(data).then((res) => {
        if (res.flag) {
          this.$notice({
            type: "success",
            message: "配课成功",
          }).isShow();
          this.$store.dispatch("getSchoolInfo");
          this.getfind();
          this.getStuInfo();
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    // 学生毕业
    graduationStudent(str) {
      let data = {
        gradeId: this.$route.query.id,
        ids: str,
      };
      this.api.admin.batchGraduation(data).then((res) => {
        if (res.flag) {
          this.$notice({
            type: "success",
            message: "批量毕业学生成功",
          }).isShow();
          this.$store.dispatch("getSchoolInfo");
          this.getStuInfo();
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    // 选项变化
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 多选禁用
    selectEnable(row) {
      // 0 需要用常量判断
      if (this.isCheck) {
        if (row.status === 0) {
          return true;
        } else {
          return false; // 不禁用
        }
      } else if (this.isGraduation) {
        return true;
      }
    },
    // todo 非公益配课 确定操作
    handleConfigSubmit() {
      if (this.multipleSelection.length != 0) {
        if (this.multipleSelection.length > this.peike) {
          this.$notice({
            message: "选择人数大于可配课人数,请重新选择",
          }).isShow();
          return;
        }
        let str = this.multipleSelection.map((res) => {
          return res.id;
        });
        this.handleUpdatePlan(str.join(","));
        this.handleConfigCancle();
      } else {
        this.$confirm("请选择要配课的学生", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      }
    },
    // 非公益配课 取消操作
    handleConfigCancle() {
      this.multipleSelection = [];
      this.isCheck = false;
    },
    onSubmit(val) {
      // 新增
      val.name = val.name.trim();
      val.idCard = val.idCard.trim();
      val.parentName = val.parentName.trim();
      val.phone = val.phone.trim();
      if (this.alertStatus === "add") {
        val.birthday = this.common.dateFormat(val.birthday, "yyyy-MM-dd");
        val.photo = val.photo.split(".com")[1];
        this.api.admin.addStudent(val).then((res) => {
          if (res.flag) {
            this.getStuInfo();
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          } else {
            this.$notice({
              message: res.message,
            }).isShow();
          }
          this.$refs.comdialog.isClose();
        });
      } else if (this.alertStatus === "edit") {
        val.birthday = this.common.dateFormat(val.birthday, "yyyy-MM-dd");
        val.photo = val.photo.split(".com")[1];
        val.sex = parseInt(val.sex);
        this.api.admin.updateStu(val, val.id).then((res) => {
          if (res.flag) {
            this.getStuInfo();
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          } else {
            this.$notice({
              message: res.message,
            }).isShow();
          }
          this.$refs.comdialog.isClose();
        });
      }
    },
    //打开编辑弹窗
    addStu() {
      this.$refs.comdialog.isOpen();
      this.alertStatus = "add";
      this.stuInfo = {
        name: "",
        idCardType: 0,
        idCard: "",
        parentName: "",
        photo: "",
        birthday: "",
        gradeId: "",
        sex: 1,
        phone: "",
      };
    },
    handleEdit(val, type) {
      this.$refs.comdialog.isOpen();
      this.alertStatus = type;
      if (type == "view") {
        this.stuInfo = {
          id: val.id,
          name: val.name,
          idCardType:
            val.idCardType == "0"
              ? "身份证"
              : val.idCardType == "1"
              ? "护照"
              : "其他证件",
          idCard: val.idCard,
          parentName: val.parentName,
          photo: val.photo ? this.constant.URL_admin + val.photo : "",
          birthday: val.birthday,
          gradeId: this.$route.query.name,
          sex: val.sex == "1" ? "男" : "女",
          phone: val.phone,
        };
      } else {
        this.stuInfo = {
          id: val.id,
          name: val.name,
          idCardType: val.idCardType,
          idCard: val.idCard,
          parentName: val.parentName,
          photo: val.photo ? this.constant.URL_admin + val.photo : "",
          birthday: val.birthday,
          gradeId: val.gradeId,
          sex: parseInt(val.sex),
          phone: val.phone,
        };
      }
    },
    //关闭编辑弹窗
    comdialogClose() {},
    // 打开更多操作弹窗 赋值已选数据
    handleDel(value) {
      this.moreHandleInfo = true;
      this.tabMoreInfo = value;
    },
    // 更多操作关闭时清空已选数据
    handleMoreInfoClose() {
      this.tabMoreInfo = {};
    },
    // 转学/毕业/删除
    handleMoreInfo(val) {
      if (val !== this.constant.stuStatus.atSchool) {
        this.$confirm(
          `此操作将${
            val === this.constant.stuStatus.goSchool ? "转学" : "毕业"
          }该学员, 是否继续?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            let data = {
              type: val,
              beforeGrade: this.tabMoreInfo.gradeId,
              gradeId: 0,
            };
            this.api.admin
              .changeSchool(data, this.tabMoreInfo.id)
              .then((res) => {
                if (res.flag) {
                  this.moreHandleInfo = false;
                  this.$notice({
                    type: "success",
                    message: `学生${
                      val === this.constant.stuStatus.goSchool ? "转学" : "毕业"
                    }成功`,
                  }).isShow();
                  this.init();
                }
              });
          })
          .catch(() => {
            this.$notice({
              type: "info",
              message: "已取消操作",
            }).isShow();
          });
      }
    },
    // 删除学生
    deleteStudent() {
      this.$confirm("此操作将永久删除该学员, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.api.admin.updateDeleteStatus(this.tabMoreInfo.id).then((res) => {
            this.moreHandleInfo = false;
            if (res.flag) {
              this.$notice({
                type: "success",
                message: "删除成功!",
              }).isShow();
              this.init();
            } else {
              this.$notice({
                type: "warn",
                message: "删除失败!",
              }).isShow();
            }
          });
        })
        .catch(() => {
          this.$notice({
            type: "info",
            message: "已取消删除",
          }).isShow();
        });
      this.getStuInfo();
    },
    //批量导入学生
    handleStuUpload() {
      this.$confirm(
        "请确认您进行的是新增操作，如果完善学生信息请使用“批量完善学生信息”功能。",
        "信息",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      ).then(() => {
        this.uploadType = 1;
        this.handleStuAlertShow1 = true;
      });
    },
    handleStuComplete() {
      this.uploadType = 2;
      this.handleStuAlertShow2 = true;
    },
    //上传成功
    uploadSuccess(res) {
      this.uploadStatus = false;

      if (res.flag) {
        this.handleStuAlertShow1 = false;
        this.handleStuAlertShow2 = false;
        this.isAllCorrect = res.data.isAllCorrect;
        if (res.data.isAllCorrect) {
          this.$notice({
            type: "success",
            message: res.message,
          }).isShow();
          this.handleuploadStatus = false;
          this.getStuInfo();
        } else {
          if (!res.data.isCorrect) {
            this.$message({
              message: "内容有误，请检查!",
              type: "warning",
              customClass: "zZindex",
            });
          }
          this.handleuploadStatus = true;
          this.uploadDialogOperate = res.data.isCorrect;
          this.uploadList = res.data.dtos;

          //判断数据的准确性
          this.uploadList.forEach((item) => {
            this.checkName(item);
            this.checkSex(item);
            this.checkBirthday(item);
            this.checkIdCard(item);
            this.checkParentName(item);
            this.checkPhone(item);
          });
          this.handleUploadInput();
        }
      } else {
        this.$notice({
          type: "success",
          message: res.message,
        }).isShow();
      }
    },
    // 下载完善信息列表
    download() {
      this.api.admin.download({ gradeId: this.$route.query.id }).then((res) => {
        const content = res.data;
        const blob = new Blob([content], { type: "application/vnd.ms-excel" });
        if ("download" in document.createElement("a")) {
          const elink = document.createElement("a");
          elink.download = this.$route.query.name + "学生名单.xlsx";
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
          this.$notice({
            type: "success",
            message: "导出成功",
          }).isShow();
        }
      });
    },
    //分页 todo 待测试
    changePage(val) {
      this.params.page = val;
      this.getStuInfo();
    },
    //删除学生信息
    delStuData(data, index) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.uploadList.splice(index, 1);
          this.handleUploadInput();
        })
        .catch(() => {
          this.$notice({
            type: "warn",
            message: "已取消删除",
          }).isShow();
        });
    },
    //添加学生,切换证件类型
    changeStuCardType(val) {
      this.stuCardType = val;
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  width: 100%;
}
tbody {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  height: auto;
}
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  word-break: break-all;
}
.class_home_page {
  .config {
    width: 200px;
    height: 20px;
    line-height: 20px;
    background: #228cec;
    color: #fff !important;
    font-size: 20px;
    font-weight: bold;
    padding: 18px 40px;
    cursor: pointer;
    text-align: center;
  }

  .btns {
    margin-top: 50px;
    @include flex(row, flex-start, center);

    .config_submit,
    .config_cancal {
      width: 80px;
      height: 20px;
      line-height: 20px;
      background: #228cec;
      color: #fff !important;
      font-size: 20px;
      font-weight: bold;
      padding: 18px 40px;
      cursor: pointer;
      margin-right: 16px;
      text-align: center;
    }
  }

  .table_top {
    @include flex(row, space-between, center);

    .title {
      font-size: 34px;
      font-family: Alibaba PuHuiTi;
      font-weight: bold;
      color: #cc1a30;
      //   margin-bottom: 30px;
    }
  }

  .top_block1 {
    flex: 1;
    @include flex(row, space-between, center);

    .title {
      font-size: 18px;
      font-weight: 400;
      color: black;
      margin-bottom: 0;
    }
  }

  .top_block2 {
    flex: 1;
    @include flex(row, flex-end, center);

    .table_top_btn_default {
      margin-left: 10px;
    }
  }

  .handleStuAlertShow {
    @include flex(column, felx-start, flex-start);

    .title {
      font-size: 0.2rem;
      line-height: 0.35rem;
      margin: 20px 0px 10px;
    }

    .btn {
      background: #228cec;
      color: #fff;
      height: 0.4rem;
      line-height: 0.4rem;
      border-radius: 0.25rem;
      font-size: 0.2rem;
      text-align: center;
      display: block;
    }
  }
  /deep/ .batch_result .el-dialog {
    background: transparent;
  }
  /deep/ .batch_result .el-dialog__body,
  /deep/ .batch_result .el-dialog__header {
    padding: 0;
  }
  /deep/ .batch_result .el-dialog__headerbtn {
    top: 36px;
    right: 44px;
  }
  /deep/ .batch_result .el-dialog__headerbtn .el-dialog__close {
    font-size: 32px;
    color: #ffffff;
  }
  /deep/ .batch_result .el-input__inner {
    border: none;
    height: 0.341667rem /* 41/120 */;
  }
  /deep/ .batch_result .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #3a3c42;
    height: 0.541667rem /* 65/120 */;
    // vertical-align: bottom;
  }
  /deep/ .batch_result .layui-table td,
  .layui-table th {
    padding: 0.075rem 0.166667rem /* 20/120 */;
  }

  .dialog_title {
    display: block;
    width: 100%;
    height: 0.9rem /* 108/120 */;
    line-height: 0.9rem;
    background: #bb3037;
    text-align: center;
    font-size: 0.4rem /* 48/120 */;
    font-weight: 500;
    color: #ffffff;
    padding: 0;
    border-radius: 0px 60px 0px 0px;
    font-family: Alibaba PuHuiTi;
  }
  .dialog_table_th {
    position: relative;
    height: 0.75rem /* 90/120 */;
    line-height: 0.75rem /* 90/120 */;
    background: #f8f5f6;
    font-family: Alibaba PuHuiTi;
  }
  .dialog_table_th_sl {
    position: absolute;
    // display:-moz-inline-box;
    display: inline-block;
    right: 0.03rem;
    width: 2px /*no*/;
    height: 0.32rem;
    background: #bb3037;
  }
  .custTab {
    margin-top: -15px;
  }
  .custTab thead th,
  .custTab tbody td {
    text-align: center;
    font-size: 0.283333rem /* 34/120 */;
    font-weight: 500;
    font-family: Alibaba PuHuiTi;
  }
  .dialog_table_tbody {
    padding-top: 0.083333rem /* 10/120 */;
  }
  .dialog_table_tbody .err_tip {
    position: absolute;
    top: 0.51667rem;
    display: inline-block;
    margin-left: 0.9rem;
    padding: 0 0.133333rem /* 16/120 */;
    height: 0.233333rem /* 28/120 */;
    line-height: 0.233333rem /* 28/120 */;
    background: #bb3037;
    color: #ffffff;
    border-radius: 0 0 5px 5px;
    font-size: 0.116667rem /* 14/120 */;
    div {
      display: flex;
      align-items: center;
    }
    span {
      padding-left: 5px;
    }
  }
  .err_tip.name {
    margin-left: 2.03rem;
  }
  .err_tip.sex {
    margin-left: 4.52rem;
  }
  .err_tip.brithDay {
    margin-left: 5.65rem;
  }
  .err_tip.idCard {
    margin-left: 7.63rem;
  }
  .err_tip.parentName {
    margin-left: 9.96rem;
  }
  .err_tip.phone {
    margin-left: 12.23rem;
  }
  .dialog_table_tr {
    // margin-bottom: .3125rem;
    height: 0.516667rem /* 62/120 */;
    line-height: 0.516667rem /* 62/120 */;
    background: #f8f5f6;
  }
  .inpsty {
    align-items: center;
    min-width: 0.8rem;
    width: 100%;
    height: 0.341667rem /* 41/120 */;
    // font-size: .2rem /* 24/120 */;
    font-size: 0.14rem;
    text-align: center;
    display: flex;
    justify-content: center;
    resize: none;
    outline: none;
    border: 1px solid #bb3037;
    border-radius: 5px;
  }
  .el-textarea {
    text-align: center;
  }
  .dialog_table_th_icon {
    width: 0.4rem;

    img {
      margin-top: -0.04rem;
    }
  }
  .dialog_table_th_num {
    width: 0.8rem;
  }
  .dialog_table_th_sex {
    width: 0.8rem;
  }
  .dialog_table_th_birth {
    width: 1.6rem;
  }
  .dialog_table_th_cardId {
    width: 2rem;
  }
  .dialog_table_th_phone {
    width: 1.6rem;
  }
  .btn_container {
    text-align: center;
  }
  .btn_sty {
    height: 0.558333rem /* 67/120 */;
    padding: 0 0.316667rem /* 38/120 */;
    background: #bb3037;
    border-radius: 5px;
    font-size: 0.283333rem /* 34/120 */;
    font-weight: 500;
    color: #ffffff;
    font-family: Alibaba PuHuiTi;
  }
  .dialog_table_tbody {
    &::-webkit-scrollbar {
      width: 0.116667rem /* 14/120 */;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track {
      background: #eeeeee;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bb3037;
      border-radius: 0.05rem /* 6/120 */;
    }
  }
}
</style>
<style lang="scss">
.class_home_page {
  .handleStuAlertShow {
    .el-upload {
      width: 100%;
    }
  }
}
.zZindex {
  z-index: 3000 !important;
}
</style>
